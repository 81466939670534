<template>
    <div>
        <DocumentNotLoaded v-if="notLoaded" :document-title="'message.invoice'"/>
        <template v-if="!notLoaded">
            <appic-page-title-bar v-if="!notLoaded" ref="breadcrumbBar" :documentTitle="updateMode ? Invoice__invno : ''" help-page-component="AddInvoice"/>
            <app-section-loader :status="loader"/>
            <v-container fluid grid-list-xl pb-0 pt-3 px-3>
                <v-layout row wrap>
                    <InvoiceAlert
                            :alert="$t('message.closeAlert')"
                            class="mt-3"
                            mode="add"
                            type="warning"
                            v-if="addMode && newInvoiceUnsaved"
                    />
                    <InvoiceAlert
                            :alert="$t('message.closeAlert')"
                            class="mt-3"
                            mode="update"
                            type="warning"
                            v-if="updateMode && updatedInvoiceUnsaved"
                    />
                    <app-card
                            :fullBlock="true"
                            colClasses="xl12 lg12 md12 sm12 xs12"
                    >
                        <v-form lazy-validation ref="invoiceForm" v-model="validForm">
                            <v-overlay
                                    :value="loader"
                                    absolute
                                    opacity="0.15"
                            >
                                <v-row>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                                color="primary"
                                                indeterminate
                                                size="40"
                                                width="6"
                                        ></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-overlay>
                            <v-expansion-panels
                                    accordion
                                    multiple
                                    v-model="panel"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <InvoiceGeneralInfo v-if="loader"/>
                                        <template v-if="!loader">
                                            <v-layout row pt-6 v-if="false">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveInvoice') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allActiveInvoices"
                                                            :placeholder="$t('message.startTypingToSearch')"
                                                            dense
                                                            hide-details
                                                            item-value="Invoice.id"
                                                            item-text="Invoice.invno"
                                                            required
                                                            solo
                                                            @change="loadAnotherActiveInvoice($event)"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Invoice.invno }}</span>
                                                                <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                                <span class="font-xs"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                            </div>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row pt-3 v-if="Invoice__type == 'ds' && addMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedContract') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allUnInvoicedContracts"
                                                            :label="Invoice__Contract__id ? '' : $t('message.required')"
                                                            :loading="loadingRelatedContract"
                                                            :value="Invoice__Contract__id"
                                                            :item-text="item => [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-value="Contract.id"
                                                            required
                                                            return-object
                                                            solo
                                                            @change="unInvoicedContract = $event"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                                <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            </div>
                                                        </template>
                                                        <template v-slot:selection="{item}">
                                                            <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row v-if="Invoice__type == 'wh' && addMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedSalesOrder') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allUnInvoicedSalesOrders"
                                                            :label="Invoice__Localsalesorder__id ? '' : $t('message.required')"
                                                            :loading="loadingRelatedSalesOrder"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-value="Localsalesorder.id"
                                                            item-text="Localsalesorder.title"
                                                            required
                                                            solo
                                                            v-model="Invoice__Localsalesorder__id"
                                                            @click="loadingRelatedSalesOrder = true"
                                                            @blur="loadingRelatedSalesOrder = false"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Localsalesorder.title }}</span>
                                                                <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            </div>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row pt-3 v-if="Invoice__type == 'ds' && updateMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>{{ Invoice__Contract__version != null ? [Invoice__Contract__title, Invoice__Contract__revision_no , Invoice__Contract__partial_no].filter(Boolean).join('-') : Invoice__Contract__title }}</v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-if="Invoice__type == 'wh' || Invoice__type == 'ds'">{{ Invoice__Customer__title }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-else>
                                                    <v-autocomplete
                                                            :items=allBuyers
                                                            :label="Manual__Invoice__customer_id ? '' : $t('message.required')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-text="Customer.title"
                                                            item-value="Customer.id"
                                                            required
                                                            solo
                                                            v-model="Manual__Invoice__customer_id"
                                                            @change="buyerChanged"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.buyerTerms') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>{{ Invoice__Paymentstatement__title != '' ? Invoice__Paymentstatement__title : $t('message.buyerTermMissing') }}</v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-if="Invoice__type == 'wh' || Invoice__type == 'ds'">{{ Invoice__Office__title }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-else>
                                                    <v-select
                                                            :items=offices
                                                            :label="Manual__Invoice__office_id ? '' : $t('message.required')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-text="Office.title"
                                                            item-value="Office.id"
                                                            required
                                                            solo
                                                            v-model="Manual__Invoice__office_id"
                                                            @change="officeChanged"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.invoiceNumber') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-text-field
                                                            :value="Invoice__invno"
                                                            :label="Invoice__invno ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            required
                                                            @change="Invoice__invno = $event"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.invoiceDate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-menu
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            max-width="290px"
                                                            min-width="290px"
                                                            ref="invoiceDateMenu"
                                                            transition="scale-transition"
                                                            v-model="invoiceDateMenu"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                    :label="Invoice__invdate ? '' : $t('message.required')"
                                                                    :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                    :value="computedInvoiceDateFormatted"
                                                                    append-icon="event"
                                                                    clearable
                                                                    class="force-text-left"
                                                                    hint="DD/MM/YY format"
                                                                    hide-details="auto"
                                                                    background-color="white"
                                                                    persistent-hint
                                                                    solo
                                                                    v-on="on"
                                                                    @click:clear="Invoice__invdate = null"
                                                                    @click:append="invoiceDateMenu = true"
                                                            />
                                                        </template>
                                                        <v-date-picker
                                                                first-day-of-week="1"
                                                                locale-first-day-of-year="4"
                                                                show-week
                                                                v-model="Invoice__invdate"
                                                                @input="invoiceDateMenu = false"
                                                        />
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.posted') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ Invoice__is_posted == 1 ? $t('message.yes'): $t('message.no') }}</v-flex>
                                            </v-layout>
                                            <v-layout row v-if="Invoice__Contract__office_id == 1 && updateMode">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.lhdnValidated') }}</v-flex>
                                                <template v-if="lhdn.validated && lhdn.submitted">
                                                    <v-flex xs6 lg3 md4 py-1>
                                                        <div class="d-flex flex-row align-center">
                                                            <span class="font-weight-bold">{{ $t('message.yes') }}</span>
                                                            <span class="font-weight-bold ml-5">{{ lhdn.dateValidated }}</span>
                                                            <v-tooltip bottom>
                                                                <template  v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        :href="lhdn.validationLink"
                                                                        class="ml-5"
                                                                        target="_blank"
                                                                        icon
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon color="green darken-2">mdi-link</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.validationLink') }}</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-flex>
                                                </template>
                                                <template v-else-if="!lhdn.validated && lhdn.submitted">
                                                    <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center font-weight-bold">
                                                        {{ $t('message.submitted') }}
                                                        <v-tooltip bottom v-if="$can('access','LhdnElectronicInvoice')">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn :loading="loading.updatingLhdnStatus" icon @click="updateLhdnStatus()" v-on="on">
                                                                    <v-icon color="success darken-1">refresh</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ $t('message.updateStatus') }}</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </template>
                                                <template v-else>
                                                    <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ $t('message.no') }}</v-flex>
                                                </template>
                                            </v-layout>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <Transition>
                                            <InvoiceItemList v-if="loader"/>
                                        </Transition>
                                        <v-flex lg12 mb-0 pb-0 mt-3 pt-0 v-if="Invoice__type == 'ds' && Invoice__Contract__id == null">
                                            <v-alert
                                                    border="left"
                                                    color="warning"
                                                    dense
                                                    icon="warning"
                                                    text
                                                    width="500px"
                                            >{{ $t('message.errors.noRelatedContractSelected') }}</v-alert>
                                        </v-flex>
                                        <v-flex lg12 mb-0 pb-0 mt-3 pt-0 v-if="Invoice__type == 'wh' && Localsalesorder__id == null">
                                            <v-alert
                                                    border="left"
                                                    color="warning"
                                                    dense
                                                    icon="warning"
                                                    text
                                                    width="500px"
                                            >{{ $t('message.errors.noRelatedLocalsalesorderSelected') }}</v-alert>
                                        </v-flex>
                                        <template v-if="!loader">
                                            <InvoiceSalesItemList :updateMode="this.updateMode" v-if="Invoice__type == 'ds' && Invoice__Contract__id != null"/>
                                            <LocalSalesItemList :updateMode="this.updateMode" v-else-if="Invoice__type == 'wh' && Invoice__Localsalesorder__id != null"/>
                                            <ManualInvoiceItemList :updateMode="this.updateMode" v-else-if="Invoice__type == 'manual'"/>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel v-if="$can('update','AgentCommission') && Invoice__Agent__has_commission === 1 && updateMode === true">
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.agentCommission') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.agent') + ' - ' + Invoice__Agent__otsname }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-checkbox
                                                    :label="$t('message.yes')"
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Invoice__has_agent_commission"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <template v-if="Invoice__has_agent_commission == 1">
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.amount') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                    <v-text-field
                                                        :value="Invoice__agent_commission_calc__unit_price"
                                                        readonly
                                                        autocomplete="off"
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        style="background-color: lightgrey !important;"
                                                        suffix="USD/M3"
                                                    />
                                                    <div class="mx-2 pt-2 font-weight-bold"> X </div>
                                                    <v-text-field
                                                        :label="Invoice__agent_commission_calc__quantity ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                                        :value="Invoice__agent_commission_calc__quantity"
                                                        autocomplete="off"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        min="0"
                                                        required
                                                        solo
                                                        suffix="M3"
                                                        @change="updateCommissionForAgent('Invoice__agent_commission_calc__quantity',$event)"
                                                    />
                                                    <div class="mx-2 pt-2 font-weight-bold"> = </div>
                                                    <v-text-field
                                                        :value="Invoice__agent_commission_amount"
                                                        readonly
                                                        autocomplete="off"
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        style="background-color: lightgrey !important;"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center"></v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">
                                                    <v-btn
                                                        :loading="loading.saveCommission"
                                                        style="width: 50% !important;"
                                                        color="default"
                                                        small
                                                        @click="saveAgentCommission()"
                                                    >{{ $t('message.saveCommission') }}</v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.bankDetails') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredBankAccounts"
                                                        clearable
                                                        dense
                                                        item-value="Bank.id"
                                                        item-text="Bank.title"
                                                        hide-details
                                                        solo
                                                        v-model="Invoice__bank_id"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ br2nl(item.Bank.account) }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ br2nl(item.Bank.account) }} </span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gstVatPpnRate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="gstRates"
                                                        :label="Invoice__gstpercent ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        item-value="Gstrate.value"
                                                        item-text="Gstrate.title"
                                                        solo
                                                        required
                                                        v-model="Invoice__gstpercent"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.exchangeRateForGstVatPpn') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                        :value="Invoice__gstrmrate"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Invoice__gstrmrate = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.salesColleague') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Invoice__Salescontact__name }}</v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.operations') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-2 class="align-center">{{ $t('message.remarks') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-textarea
                                                        :rules="[...validationRules.paragraph]"
                                                        :value="Invoice__remark"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        rows="3"
                                                        solo
                                                        @change="Invoice__remark = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-2 class="align-center">{{ $t('message.addressToUse') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                    :items=addressesToUse
                                                    clearable
                                                    dense
                                                    hide-details
                                                    solo
                                                    v-model="Invoice__usenotifyaddress"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Invoice__usenotifyaddress == 2">
                                            <v-flex xs6 lg2 md3 pl-5 py-1></v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="Invoice__otheraddress"
                                                    clearable
                                                    dense
                                                    hide-details
                                                    rows="3"
                                                    solo
                                                    @change="Invoice__otheraddress = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.showSignatureBox') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                    class="mt-0 pt-0"
                                                    dense
                                                    hide-details
                                                    v-model="Invoice__json_show_sign_box"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.hideForexConversion') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                    :true-value="1"
                                                    :false-value="null"
                                                    class="mt-0 pt-0"
                                                    dense
                                                    hide-details
                                                    v-model="Invoice__hide_conversion"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.hideSignatureStamp') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                    class="mt-0"
                                                    dense
                                                    hide-details
                                                    v-model="Invoice__hidestamp"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredFooterNotes') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-btn @click.stop="footerNotesDialog = true" small>{{ $t('message.manage') }}</v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details
                                                    solo
                                                    v-model="Invoice__longform"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <template v-if="$can('create','TemporaryInvoice') && updateMode == true && [1].includes(Invoice__Contract__office_id)">
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryCurrency') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-select
                                                        :items="allCurrencies"
                                                        class="custom py-0 mt-0 bg-lightgrey"
                                                        hide-details
                                                        item-text="Currency.code"
                                                        item-value="Currency.id"
                                                        reverse
                                                        v-model="Invoice__tmp_currency_id"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryExchangeRate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-text-field
                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                        :value="Invoice__tmp_currencyrate"
                                                        class="custom py-0 mt-0 bg-lightgrey"
                                                        dense
                                                        hide-details
                                                        @change="Invoice__tmp_currencyrate = $event"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryExchangeRateDate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        ref="invoiceDateMenu"
                                                        transition="scale-transition"
                                                        v-model="temporaryInvoiceDateMenu"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedTemporaryInvoiceDateFormatted"
                                                                append-icon="event"
                                                                clearable
                                                                class="force-text-left"
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Invoice__tmp_invdate = null"
                                                                @click:append="temporaryInvoiceDateMenu = true"
                                                            />
                                                        </template>
                                                        <v-date-picker
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="Invoice__tmp_invdate"
                                                            @input="temporaryInvoiceDateMenu = false"
                                                        />
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs12 lg6 md9 pt-1 py-4 pl-3 class="grey lighten-3">
                                                    <div>
                                                        <v-btn
                                                            :loading="loading.saveTemp"
                                                            class="ml-3"
                                                            small
                                                            @click.stop="saveTemporaryInvoiceFields"
                                                        >
                                                            {{ $t('message.save') }}
                                                        </v-btn>
                                                        <v-btn class="ml-3" small :loading="loading.temporaryInvoice" @click.stop="printTemporaryInvoice">
                                                            <v-icon small>far fa-file-pdf</v-icon>
                                                            <span class="ml-1">{{ $t('message.temporaryInvoice') }}</span>
                                                        </v-btn>
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel v-if="false">
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.forGpIntegration') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gpTaxCode') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="gpTaxCodes"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        item-value="Taxcode.title"
                                                        item-text="Taxcode.title"
                                                        required
                                                        solo
                                                        v-model="Invoice__headertaxschedule"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.customerNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Invoice__Company__GPCustomerNumber != null && Invoice__Company__GPCustomerNumber.length > 0 ? Invoice__Company__GPCustomerNumber : $t('message.gpCustomerNumberRequired') }}</v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_invoice')">
                                        <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <DocumentHistory asset="invoices" :asset_id="Invoice__id"></DocumentHistory>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-layout row>
                                <v-flex d-flex xs12>
                                    <v-layout row px-3 pt-3 mb-3>
                                        <v-btn
                                                @click.stop="previewDialog = true"
                                                small
                                                class="ml-0"
                                        >{{ $t('message.preview') }}
                                        </v-btn>
                                        <PrintDirectSalesInvoiceButton
                                            :contract-currency-id.sync="Invoice__Contract__currency_id"
                                            :destination-id="Invoice__Contract__destinatione_id"
                                            :invoice-id="invoiceId"
                                            :invoice-currency-id.sync="Invoice__currency_id"
                                            :office-id="Invoice__Contract__office_id"
                                            :update-mode="updateMode"
                                            :list-mode="false"
                                            :sales-type-id="Invoice__Contract__salestype_id"
                                        />
                                        <v-btn
                                            :loading="loading.reset"
                                            @click="resetInvoiceForm"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                        >
                                            <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                        </v-btn>
                                        <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveInvoice"
                                            v-if="addMode == false && Invoice__is_posted != 1"
                                        >
                                            {{ $t('message.save') }}
                                        </v-btn>
                                        <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="addMode == false && Invoice__is_posted != 1"
                                            @click="cancelInvoice(Invoice__id)"
                                        >{{ $t('message.cancel') }}
                                        </v-btn>
                                        <v-btn
                                            :loading="loading.xero"
                                            class="ml-3"
                                            small
                                            v-if="updateMode == true && Invoice__is_posted != 1"
                                            @click="uploadToXero(Invoice__id)"
                                        >{{ $t('message.uploadToXero') }}
                                        </v-btn>
                                        <v-btn
                                            :disabled="lhdn.submitted"
                                            :loading="loading.lhdn"
                                            class="ml-3"
                                            small
                                            v-if="$can('access','LhdnElectronicInvoice') && updateMode == true && Invoice__Contract__office_id == 1 && lhdn.validated == false && lhdn.submitted == false"
                                            @click="submitToLhdn(Invoice__id)"
                                        >{{ $t('message.submitToLhdn') }}
                                        </v-btn>
                                        <DummyInvoice class="ml-3" :invoice-id="invoiceId" v-if="[1,21].includes(Invoice__Contract__office_id)"/>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </app-card>
                </v-layout>
            </v-container>
        </template>
        <v-dialog
            persistent
            v-model="previewDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.invoicePreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <InvoicePreview/>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="previewDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.add"
                        class="ml-3"
                        color="info"
                        small
                        @click="addInvoice"
                        v-if="addMode == true"
                    >{{ $t('message.saveNewInvoice') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == false && Invoice__is_posted != 1"
                        @click="saveInvoice"
                    >{{ $t('message.saveChanges') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="footerNotesDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.manageFooterNotes') }}</v-card-title>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="item in invoiceFooterNotes" :key="item.id" v-if="item.id != 10">
                            <v-list-item-action>
                                <v-checkbox
                                        :value="item.id"
                                        dense
                                        hide-details
                                        v-model="Invoice__footer_notes"
                                />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="font-sm wrap-text">{{ parseFooterNote(item) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            @click="footerNotesDialog = false"
                            class="ml-3"
                            color="default"
                            small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SimpleAlert
                :alert_message="this.dialogs.error_message"
                v-bind:dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {formatDate, br2nl, scrollToFirstFormValidationError, log, isObject} from "Helpers/helpers";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import router from "../../../router";
    import {Decimal} from 'decimal.js'
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";
    import InvoiceItemList from "Components/Appic/Loading/InvoiceItemList";
    import InvoiceGeneralInfo from "Components/Appic/Loading/InvoiceGeneralInfo";
    import DocumentNotLoaded from "Components/Appic/Error/DocumentNotLoaded.vue";
    import PrintDirectSalesInvoiceButton from "Components/Appic/Printing/PrintDirectSalesInvoiceButton";

    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const DummyInvoice = () => import("Components/Appic/Printing/DummyInvoice.vue");
    const InvoiceAlert = () => import("Components/Appic/InvoiceAlert");
    const InvoicePreview = () => import("Components/Appic/InvoicePreview");
    const InvoiceSalesItemList = () => import("Components/Appic/InvoiceSalesItemList");
    const LocalSalesItemList = () => import("Components/Appic/LocalSalesItemList");
    const ManualInvoiceItemList = () => import("Components/Appic/ManualInvoiceItemList");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");

    export default {
        name: "AddInvoice",
        components: {
            DocumentNotLoaded,
            DummyInvoice,
            InvoiceGeneralInfo,
            InvoiceItemList,
            DocumentHistory,
            InvoiceAlert,
            InvoicePreview,
            InvoiceSalesItemList,
            LocalSalesItemList,
            ManualInvoiceItemList,
            PrintDirectSalesInvoiceButton,
            SimpleAlert
        },
        props: ['invoiceId','contractId'],
        title: '',
        data() {
            return {
                agentCommissionDateMenu: false,
                addressesToUse: [
                    {value: 0, text: this.$t('message.customerMainAddress')},
                    {value: 1, text: this.$t('message.customerDeliveryNotifyAddress') },
                    {value: 2, text: this.$t('message.otherAddress') }
                ],
                allUnInvoicedContracts: [],
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                footerNotesDialog: false,
                invoiceDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                invoiceDateMenu: false,
                lhdn: {
                    jobId: null,
                    submitted: false,
                    validated: false,
                    dateValidated: null,
                    validationLink: null
                },
                loader: false,
                loading: {
                    add: false,
                    invoicePdf: false,
                    lhdn: false,
                    post: false,
                    reset: false,
                    save: false,
                    saveCommission: false,
                    saveTemp: false,
                    taxInvoicePdf: false,
                    temporaryInvoice: false,
                    updatingLhdnStatus: false,
                    xero: false
                },
                loadingActiveInvoice: false,
                loadingRelatedContract: false,
                loadingRelatedSalesOrder: false,
                notLoaded: false,
                panel: [0,1],
                pdfComponentKey: 0,
                pdfTaxInvoice: false,
                previewDialog: false,
                temporaryInvoiceDateMenu: false,
                unInvoicedContract: null,
                validForm: true,
                viewPdf: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapFieldsPrefixed('invoice',{
                Invoice__Agent__id: 'Invoice.Agent.id',
                Invoice__Agent__has_commission: 'Invoice.Agent.has_commission',
                Invoice__Agent__otsname: 'Invoice.Agent.otsname',
                Invoice__Company__GPCustomerNumber: 'Invoice.Company.GPCustomerNumber',
                Invoice__Contract__id: 'Invoice.Contract.id',
                Invoice__Contract__bank_id: 'Invoice.Contract.bank_id',
                Invoice__Contract__currency_id: 'Invoice.Contract.currency_id',
                Invoice__Contract__customer_id: 'Invoice.Contract.customer_id',
                Invoice__Contract__destinatione_id: 'Invoice.Contract.destinatione_id',
                Invoice__Contract__fsc: 'Invoice.Contract.fsc',
                Invoice__Contract__incoterm_id: 'Invoice.Contract.incoterm_id',
                Invoice__Contract__loadingport_id: 'Invoice.Contract.loadingport_id',
                Invoice__Contract__office_id: 'Invoice.Contract.office_id',
                Invoice__Contract__partial_no: 'Invoice.Contract.partial_no',
                Invoice__Contract__revision_no: 'Invoice.Contract.revision_no',
                Invoice__Contract__salestype_id: 'Invoice.Contract.salestype_id',
                Invoice__Contract__shippingport_id: 'Invoice.Contract.shippingport_id',
                Invoice__Contract__title: 'Invoice.Contract.title',
                Invoice__Contract__version: 'Invoice.Contract.version',
                Invoice__Customer__id: 'Invoice.Customer.id',
                Invoice__Customer__title: 'Invoice.Customer.title',
                Invoice__Deliveryaddress__address: 'Invoice.Deliveryaddress.address',
                Invoice__FreeEntry__id: 'Invoice.FreeEntry.id',
                Invoice__FreeEntry__bank_id: 'Invoice.FreeEntry.bank_id',
                Invoice__FreeEntry__currency_id: 'Invoice.FreeEntry.currency_id',
                Invoice__FreeEntry__office_id: 'Invoice.FreeEntry.office_id',
                Invoice__id: 'Invoice.id',
                Invoice__agent_commission_calc__setting_id: 'Invoice.agent_commission_calc.setting_id',
                Invoice__agent_commission_calc__unit_price: 'Invoice.agent_commission_calc.unit_price',
                Invoice__agent_commission_calc__quantity: 'Invoice.agent_commission_calc.quantity',
                Invoice__agent_commission_amount: 'Invoice.agent_commission_amount',
                Invoice__agent_commission_paid: 'Invoice.agent_commission_paid',
                Invoice__is_posted: 'Invoice.is_posted',
                Invoice__contract_id: 'Invoice.contract_id',
                Invoice__currency_id: 'Invoice.currency_id',
                Invoice__footer_notes: 'Invoice.footer_notes',
                Invoice__gstpercent: 'Invoice.gstpercent',
                Invoice__gstrmrate: 'Invoice.gstrmrate',
                Invoice__has_agent_commission: 'Invoice.has_agent_commission',
                Invoice__headertaxschedule: 'Invoice.headertaxschedule',
                Invoice__hide_conversion: 'Invoice.hide_conversion',
                Invoice__hidestamp: 'Invoice.hidestamp',
                Invoice__invdate: 'Invoice.invdate',
                Invoice__invno: 'Invoice.invno',
                Invoice__json_show_sign_box: 'Invoice.json.show_sign_box',
                Invoice__longform: 'Invoice.longform',
                Invoice__otheraddress: 'Invoice.otheraddress',
                Invoice__posted: 'Invoice.posted',
                Invoice__posted_by: 'Invoice.posted_by',
                Invoice__remark: 'Invoice.remark',
                Invoice__tmp_currency_id: 'Invoice.tmp_currency_id',
                Invoice__tmp_currencyrate: 'Invoice.tmp_currencyrate',
                Invoice__tmp_invdate: 'Invoice.tmp_invdate',
                Invoice__type: 'Invoice.type',
                Invoice__usenotifyaddress: 'Invoice.usenotifyaddress',
                Invoice__Office__title: 'Invoice.Office.title',
                Invoice__Paymentstatement__title: 'Invoice.Paymentstatement.title',
                Invoice__Paymentterm__id: 'Invoice.Paymentterm.id',
                Invoice__Paymentterm__title: 'Invoice.Paymentterm.title',
                Invoice__Salescontact__name: 'Invoice.Salescontact.name'
            },'statePrefix'),
            ...mapFieldsPrefixed('manualinvoice',{
                Manual__Invoice__id: 'Manual.Invoice.id',
                Manual__Invoice__bank_id: 'Manual.Invoice.bank_id',
                Manual__Invoice__customer_id: 'Manual.Invoice.customer_id',
                Manual__Invoice__currency_id: 'Manual.Invoice.currency_id',
                Manual__Invoice__office_id: 'Manual.Invoice.office_id',
                Manual__Invoice__paymentstatement_id: 'Manual.Invoice.paymentstatement_id',
                Manual__Invoice__title: 'Manual.Invoice.title',
            },'statePrefix'),
            ...mapFields('invoice',{
                newInvoiceSaved: 'newInvoiceSaved',
                newInvoiceUnsaved: 'newInvoiceUnsaved',
                updatedInvoiceUnsaved: 'updatedInvoiceUnsaved',
                updatedInvoiceSaved: 'updatedInvoiceSaved'
            }),
            ...mapFields('invoicesalesitem',{
                Current__Salesitems: 'current.Salesitems'
            }),
            ...mapFieldsPrefixed('invoicesalesitem',{
                Salesitem__gstpercent: 'gstpercent',
            },'statePrefix'),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('bankaccount', {
                allBankAccounts: 'allBankAccounts',
                findBankAccountById: 'findBankAccountById',
                findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
            }),
            ...mapGetters('contract',{
                getContractById: 'getContractById',
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('invoice',{
                allActiveInvoices: 'allActiveInvoices',
            }),
            ...mapGetters('salesorder',{
                getSalesOrderById: 'getSalesOrderById',
                allUnInvoicedSalesOrders: 'allUnInvoicedSalesOrders'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'gpTaxCodes',
                'gstRates',
                'invoiceFooterNotes',
                'offices',
                'statuses',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_invoice')
            },
            allowPosting: function () {
                return this.User.email == 'vanjoe@apptimber.com' // temporary
            },
            br2nl: () =>  br2nl,
            computedInvoiceDateFormatted () {
                return formatDate(this.Invoice__invdate)
            },
            computedAgentCommissionDateFormatted() {
                return formatDate(this.Invoice__agent_commission_paid)
            },
            computedTemporaryInvoiceDateFormatted() {
                return formatDate(this.Invoice__tmp_invdate)
            },
            filteredBankAccounts: function(){
                switch(this.Invoice__type){
                    case 'ds':
                        if(this.Invoice__Contract__office_id != null && this.Invoice__Contract__currency_id != null){
                            return this.findBankAccountsByOfficeAndCurrency(this.Invoice__Contract__office_id, this.Invoice__Contract__currency_id)
                        } else {
                            return []
                        }
                        break;
                    case 'wh':
                        if(this.Localsalesorder__office_id != null && this.Localsalesorder__currency_id != null){
                            return this.findBankAccountsByOfficeAndCurrency(this.Localsalesorder__office_id, this.Localsalesorder__currency_id)
                        } else {
                            return []
                        }
                        break;
                    default:
                        return this.allBankAccounts
                }
            },
            footerNoteReplacements (){
                let obj = {}
                if([1,3].includes(this.Invoice__Contract__office_id)){
                    obj.interest_charge_pct = '1.5%'
                } else {
                    obj.interest_charge_pct = '2%'
                }
                return obj
            },
            Invoice__bank_id: {
                get () {
                    let val = null
                    switch (this.Invoice__type) {
                        case 'ds':
                            val = this.Invoice__Contract__bank_id
                            break;
                        case 'wh':
                            val = this.Localsalesorder__bank_id
                            break;
                        case 'un':
                            val = this.FreeEntry__bank_id
                            break;
                    }
                    return val
                },
                set (val) {
                    switch (this.Invoice__type) {
                        case 'ds':
                            this.Invoice__Contract__bank_id = val
                            break;
                        case 'wh':
                            this.Localsalesorder__bank_id = val
                            break;
                        case 'un':
                            this.FreeEntry__bank_id = val
                            break;
                    }
                }
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_invoice')
            }
        },
        methods: {
            ...mapActions('bankaccount',{
                getAllBankAccounts: 'getAllBankAccounts',
            }),
            ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts',
                getAllContractsWithoutInvoice: 'getAllContractsWithoutInvoice'
            }),
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('invoice', {
                cancelInvoiceById: 'cancelInvoiceById',
                createInvoice: 'createInvoice',
                getAllActiveInvoices: 'getAllActiveInvoices',
                getInvoiceToUpdateById: 'getInvoiceToUpdateById',
                loadUninvoicedContract: 'loadUninvoicedContract',
                resetAllActiveInvoices: 'resetAllActiveInvoices',
                resetCurrentInvoice: 'resetCurrentInvoice',
                resetInvoice: 'resetInvoice',
                updateAgentCommission: 'updateAgentCommission',
                updateInvoice: 'updateInvoice',
                updateTemporaryInvoiceFields: 'updateTemporaryInvoiceFields'
            }),
            ...mapActions('invoicesalesitem',{
                getSalesItemsByContract: 'getSalesItemsByContract',
                resetInvoiceSalesItems: 'resetInvoiceSalesItems',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('salesorder',{
                getAllActiveSalesOrders: 'getAllActiveSalesOrders'
            }),
            ...mapActions('localsalesitem',{
                getSalesItemsBySalesOrder: 'getSalesItemsBySalesOrder',
                getSalesItemsByLocalSale: 'getSalesItemsByLocalSale'
            }),
            addInvoice(){
                if(this.$refs.invoiceForm.validate()) {
                    this.loading.add = true
                    this.createInvoice()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newInvoiceUnsaved = false
                                this.newInvoiceSaved = true
                                // reset new invoice state
                                this.resetCurrentInvoice().then(() => {
                                    //reset new invoice items state
                                    this.resetInvoiceSalesItems(this.statePrefix).then(() => {
                                        this.resetAllActiveInvoices().then(() => {
                                            router.push({
                                                name: 'update_invoice',
                                                params: {invoiceId: response.invoice_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.invoiceNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.invoiceNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.invoiceNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            buyerChanged () {},
            async cancelInvoice(val) {
                if(await this.$root.$confirm(this.$t('message.cancelInvoice'), this.$t('message.confirmations.continueInvoiceCancelAction'), {color: 'orange'})){
                    this.cancelInvoiceById(val)
                        .then((response) => {
                            if(response.status == 'status'){
                                this.$toast.success(this.$t('message.successes.invoiceDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                router.push({name: 'invoice_listing'}).catch(err => {})
                                this.loading.cancel = false
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            checkLhdnValidation () {
                if(this.Invoice__Contract__office_id === 1) {
                    api
                        .get("/lhdn/ds/" + this.invoiceId)
                        .then((response) => {
                            if(response.data.status === 'success') {
                                const lhdn = response.data.data.lhdn;
                                this.lhdn.jobId = lhdn.jobId;
                                this.lhdn.submitted = lhdn.submitted;
                                this.lhdn.validated = lhdn.validated;
                                this.lhdn.dateValidated = lhdn.dateValidated;
                                this.lhdn.validationLink = lhdn.validationLink;
                            } else {
                                // this.$toast.error(response.data.message,
                                //     {
                                //         classes: ['icon-float-left'],
                                //         icon: 'error_outline'
                                //     });
                            }
                            if(this.loading.updatingLhdnStatus) this.loading.updatingLhdnStatus = false;
                        })
                        .catch(() => {
                            // this.$toast.error(this.$t('message.errors.cannotCheckLhdnStatus'),
                            //     {
                            //         classes: ['icon-float-left'],
                            //         icon: 'error_outline'
                            //     });
                            if(this.loading.updatingLhdnStatus) this.loading.updatingLhdnStatus = false;
                        })
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            loadAnotherActiveInvoice (val) {
                router.push({name: 'update_invoice', params: { invoiceId : val}}).catch(err => {})
            },
            loadInvoiceById (val) {
                let self = this
                this.loader = true
                this.getInvoiceToUpdateById(val)
                    .then(()=>{
                        this.Salesitem__gstpercent = this.Invoice__gstpercent
                        let payload = {
                            contract_id: this.Invoice__contract_id,
                            prefix: self.statePrefix
                        }
                        this.loader = false;
                        this.$title =  this.$t('message.titles.invoice') + ': ' + this.Invoice__invno
                        this.getSalesItemsByContract(payload)
                            .then(()=>{
                                this.loader = false;
                                this.updatedInvoiceUnsaved = false
                            })
                            .catch(()=>{
                                this.loader = false;
                                this.updatedInvoiceUnsaved = false
                            })
                    })
                    .catch(() => {
                        this.loader = false
                        this.updatedInvoiceUnsaved = false
                        router
                            .push({name: 'not_found_main', params: {resource: 'invoice'}})
                            .catch(() => {
                                console.log('error')
                            })
                    })
            },
            loadLocalSalesOrderItems(localsalesorder_id){
                this.getSalesItemsBySalesOrder(localsalesorder_id)
            },
            officeChanged(){
                return true
            },
            parseFooterNote (footerNote) {
                if(footerNote.replace){
                    if(this.footerNoteReplacements[footerNote.replace] != null){
                        return footerNote.text.replace('<<' + footerNote.replace + '>>',this.footerNoteReplacements[footerNote.replace])
                    }
                } else {
                    return footerNote.text
                }
            },
            async postToGp(val) {
                if (await this.$root.$confirm(this.$t('message.postToGpQueue'), this.$t('message.confirmations.continuePostToGpQueue'), {color: 'orange'})) {
                    this.loading.post = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/invoices/" + val + "/post-gp")
                            .then(response => {
                                if (response.data.status === 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve('error')
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('error')
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status === 'success') {
                                this.$toast.success(this.$t('message.successes.invoicePosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.Invoice__is_posted = response.is_posted
                                this.Invoice__posted = response.posted
                                this.Invoice__posted_by = response.posted_by
                                this.loading.post = false
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.post = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.post = false
                        })
                }
            },
            getPrintPdfUrl() {
                return new Promise((resolve, reject) => {
                    const urlComponent = "/print-url/temporary-invoice/"
                    api
                        .get(urlComponent + this.invoiceId)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            printTemporaryInvoice() {
                this.loading.temporaryInvoice = true;
                this.getPrintPdfUrl()
                    .then(response => {
                        if(response.data.status === 'success') {
                            let tab = window.open(response.data.url, '_blank');
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                        }
                        if(this.loading.temporaryInvoice) this.loading.temporaryInvoice = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        if(this.loading.temporaryInvoice) this.loading.temporaryInvoice = false
                    })
            },
            async resetInvoiceForm() {
                if (await this.$root.$confirm(this.$t('message.resetInvoiceForm'), this.$t('message.confirmations.continueInvoiceFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentInvoice()
                        .then(() => {
                            this.resetInvoiceSalesItems(this.statePrefix)
                                .then(()=>{
                                    setTimeout(() => {
                                        this.newInvoiceSaved = false
                                        this.newInvoiceUnsaved = false
                                        this.loading.reset = false
                                        this.$toast.success(this.$t('message.invoiceFormResetNotification'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                    }, 500)
                                })
                                .catch((e) => {
                                    this.$toast.success(e,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.newInvoiceSaved = false
                                    this.newInvoiceUnsaved = false
                                    this.loading.reset = false
                                })
                        })
                        .catch((e) => {
                            this.$toast.success(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.newInvoiceSaved = false
                            this.newInvoiceUnsaved = false
                            this.loading.reset = false
                        })
                }
            },
            resetViewVars() {
                this.loading.invoicePdf = false
                this.loading.taxInvoicePdf = false
                this.viewPdf = false
                this.pdfProformaInvoice = false
            },
            saveAgentCommission() {
                this.loading.saveCommission = true;
                this.updateAgentCommission()
                    .then((response) => {
                        if (response.status === 'success') {
                            this.$toast.success(this.$t('message.successes.agentCommissionUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.saveCommission = false
                            this.updatedInvoiceUnsaved = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.saveCommission = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.saveCommission = false
                    })
            },
            saveInvoice() {
                let self = this
                if(this.$refs.invoiceForm.validate()) {
                    this.loading.save = true
                    this.updateInvoice()
                        .then((response) => {
                            if (response.status == 'success') {
                                const payload = {
                                    contract_id: this.Invoice__contract_id,
                                    prefix: self.statePrefix
                                }
                                this.getSalesItemsByContract(payload)
                                    .then(() => {
                                        this.$toast.success(this.$t('message.successes.invoiceUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        this.loading.save = false
                                        this.updatedInvoiceUnsaved = false
                                        this.pdfComponentKey = Math.floor(Math.random() * 100)
                                    })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            saveTemporaryInvoiceFields() {
                this.loading.saveTemp = true
                this.updateTemporaryInvoiceFields()
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.invoiceUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.saveTemp = false
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.saveTemp = false
                    })
            },
            async submitToLhdn(val) {
                if (await this.$root.$confirm(this.$t('message.submitToLhdnQueue'), this.$t('message.confirmations.continueSubmissionToLhdnAction'), {color: 'orange'})) {
                    this.loading.lhdn = true
                    const promise = new Promise((resolve, reject) => {
                        api
                            .post("/lhdn/document", {
                                document_id:  val,
                                document_type: 'ds'
                            })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(() => {
                                reject('error');
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceSubmitted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                );
                                this.lhdn.submitted = true;
                                this.loading.lhdn = false;
                                this.lhdn.jobId = response.job_id;
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                );
                                this.loading.lhdn = false;
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            );
                            this.loading.lhdn = false;
                        })
                }
            },
            viewInvoice() {
                this.loading.invoicePdf = true
                this.pdfTaxInvoice = false
                this.viewPdf = true
            },
            viewTaxInvoice() {
                this.loading.taxInvoicePdf = true
                this.pdfTaxInvoice = true
                this.viewPdf = true
            },
            viewTemporaryInvoicePdf() {
                // let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                // contractNumber = contractNumber.replace('.','')
                // const document = encodeURIComponent(contractNumber + '_Inv_' + this.Invoice__invno)
                // let tab = window.open(
                //     process.env.VUE_APP_PDF_SERVER
                //     + '/api/render/'
                //     + document
                //     + '?url='
                //     + process.env.VUE_APP_BASE_URL
                //     + '/v1/converted-invoices/print/'
                //     + this.invoiceId
                //     + '/'
                //     + uuidv4()
                //     + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                //     , "_blank");
                // tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                // this.openedTabs.push(tab.name)
            },
            viewInvoicePdf () {
                // let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                // contractNumber = contractNumber.replace('.','')
                // const document = encodeURIComponent(contractNumber + '_Inv_' + this.Invoice__invno)
                // // let user = JSON.parse(localStorage.getItem('user'))
                // let tab = window.open(
                //     process.env.VUE_APP_PDF_SERVER
                //     + '/api/render/'
                //     + document
                //     + '?url='
                //     + process.env.VUE_APP_BASE_URL
                //     + '/v1/invoices/print/'
                //     + this.invoiceId
                //     + '/'
                //     + uuidv4()
                //     + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                //     , "_blank");
                // tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                // this.openedTabs.push(tab.name)
            },
            viewTaxInvoicePdf () {
                let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + '_Tax_Inv_' + this.Invoice__invno)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/tax-invoices/print/'
                    + this.invoiceId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateCommissionForAgent( field, value) {
                this[field] = value;

                const unitPrice = new Decimal(this.Invoice__agent_commission_calc__unit_price + '');
                const quantity =  new Decimal(value + '');
                this.Invoice__agent_commission_amount = unitPrice.times(quantity).toDecimalPlaces(2, Decimal.ROUND_DOWN).toFixed()

                // const originalAmount = new Decimal(this.Invoice__amount + '');
                // const agentCommission = new Decimal(this.Invoice__agent_commission_amount + '');
                // this.Invoice__net_amount = originalAmount.minus(agentCommission).toFixed();

                //is this a fix?
                this.Invoice__agent_commission_calc__unit_price = unitPrice.toFixed();
            },
            updateLhdnStatus() {
                this.loading.updatingLhdnStatus = true;
                api
                    .get("/lhdn/jobs/status/" + this.lhdn.jobId)
                    .then(response => {
                        if (response.data.status == 'success') {
                            this.checkLhdnValidation();
                        } else {
                            this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            );
                            this.loading.updatingLhdnStatus = false;
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        );
                        this.loading.updatingLhdnStatus = false;
                    })
            },
            async uploadToXero (val) {
                if(this.Invoice__gstrmrate != null) {
                    if (await this.$root.$confirm(this.$t('message.uploadToXeroQueue'), this.$t('message.confirmations.continueUploadToXeroAction'), {color: 'orange'})) {
                        this.loading.xero = true
                        let promise = new Promise((resolve, reject) => {
                            api
                                .put("/invoices/" + val + "/upload-xero")
                                .then(response => {
                                    if (response.data.status == 'success') {
                                        resolve(response.data)
                                    } else {
                                        resolve(response.data.message)
                                    }
                                })
                                .catch(error => {
                                    reject(error);
                                })
                                .finally(() => {
                                    resolve('error')
                                })
                        })
                        promise
                            .then((response) => {
                                if (response.status == 'success') {
                                    this.$toast.success(this.$t('message.successes.invoiceUploaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.Invoice__is_posted = 1
                                    this.loading.xero = false
                                } else {
                                    this.$toast.error(response,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.xero = false
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.invoiceNotUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.xero = false
                            })
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.exRateXeroMissing'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            }
        },
        watch: {
            Invoice__gstpercent (val) {
                this.Salesitem__gstpercent = val
            },
            Localsalesorder__id (val) {
                if(val != null) {
                    let salesorder = this.getSalesOrderById(val)
                    this.Customer__title = salesorder.Customer.title
                    this.Localsalesorder__id = salesorder.Localsalesorder.id
                    this.Localsalesorder__bank_id = salesorder.Bank.id
                    this.Localsalesorder__currency_id = salesorder.Localsalesorder.currency_id
                    this.Localsalesorder__customer_id = salesorder.Customer.id
                    this.Localsalesorder__office_id = salesorder.Office.id
                    this.Localsalesorder__title = salesorder.Localsalesorder.title
                    this.Invoice__currency_id = salesorder.Localsalesorder.currency_id
                    this.Office__title = salesorder.Office.title
                    this.Salescontact__name = salesorder.Salescontact.name
                    this.Paymentterm__id = salesorder.Paymentstatement.id
                    this.Paymentterm__title = salesorder.Paymentstatement.title
                    this.loadLocalSalesOrderItems(val)
                    this.loadingRelatedSalesOrder = false
                    this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === salesorder.Office.id)?.Office?.taxcode
                    this.Invoice__gstpercent = this.offices.find(office => office.Office.id === salesorder.Office.id)?.Office?.taxrate
                }
            },
            Invoice__type (val) {
                if(val == 'ds'){
                    let contract = this.getContractById(this.Invoice__Contract__id)
                    if(contract) {
                        this.Invoice__Customer__title = contract.Customer.title
                        this.Invoice__Office__title = contract.Office.title
                        this.Invoice__Salescontact__name = contract.Salescontact.name
                        if (contract.Contract.salestype_id == 5) {
                            this.Invoice__currency_id = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.currency_id;
                        } else {
                            this.Invoice__currency_id = contract.Contract.currency_id
                        }
                        this.Invoice__contract_id = contract.Contract.id
                        this.Invoice__Paymentterm__id = contract.Paymentstatement.id
                        this.Invoice__Paymentterm__title = contract.Paymentstatement.title
                        this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxcode
                        this.Invoice__gstpercent = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxrate
                    }
                } else if(val == 'wh'){
                    let salesOrder = this.getSalesOrderById(this.Localsalesorder__id)
                    if(salesOrder) {
                        this.Customer__title = salesOrder.Customer.title
                        this.Invoice__currency_id = salesOrder.Localsalesorder.currency_id
                        this.Office__title = salesOrder.Office.title
                        this.Salescontact__name = salesOrder.Salescontact.name
                        this.Paymentterm__id = salesOrder.Paymentstatement.id
                        this.Paymentterm__title = salesOrder.Paymentstatement.title
                        this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === salesOrder.Office.id).Office.taxcode
                        this.Invoice__gstpercent = this.offices.find(office => office.Office.id === salesOrder.Office.id).Office.taxrate
                    }
                } else if(val == 'manual'){
                    this.Invoice__currency_id = this.Manual__Invoice__currency_id
                }
            },
            unInvoicedContract(val){
                log('Loading un-invoiced contract')
                let self = this
                setTimeout(function(){
                    self.loadUninvoicedContract(val)
                        .then(()=>{
                            let payload = {
                                contract_id: val.Contract.id,
                                prefix: self.statePrefix
                            }
                            self.getSalesItemsByContract(payload).then(()=>{
                                //assign unit prices to invoice unit price
                                self.Current__Salesitems.forEach(item => {
                                    item.Salesitem.invprice = item.Salesitem.unitprice
                                    if(item.type == 'othercharge'){
                                        item.Salesitem.invqty = 1
                                    }
                                    item.Salesitem.invoiceamount = item.Salesitem.invprice * item.Salesitem.invqty
                                })
                                self.updateTotals('current')
                                self.loader = false
                            })
                        })
                        .catch((err) => {
                            log(err)
                        })
                },100)
            }
        },
        created(){
            this.getAllContractsWithoutInvoice()
                .then((contracts) => {
                    // log(contracts)
                    this.allUnInvoicedContracts = contracts
                })
                .catch((err) => {
                    log(err)
                })
            if(this.allActiveInvoices.length == 0) this.getAllActiveInvoices()
            if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allUnInvoicedSalesOrders.length == 0) this.getAllActiveSalesOrders()
            if(this.$route.matched.some(({name}) => name === 'update_invoice')) {
                this.loadInvoiceById(this.invoiceId)
            } else if(this.$route.matched.some(({name}) => name === 'add_invoice')){
                this.newInvoiceUnsaved = true
                if(this.contractId != null){
                    this.loader = true
                    this.Invoice__Contract__id = this.contractId
                    let contract = this.allUnInvoicedContracts.find((d)=>{ return d.Contract.id == this.contractId})
                    if(contract) {
                        this.loadUninvoicedContract(contract)
                            .then(() => {
                                let payload = {
                                    contract_id: contract.Contract.id,
                                    prefix: 'current'
                                }
                                this.getSalesItemsByContract(payload).then(() => {
                                    //TODO assign unit prices to invoice unit price
                                    this.Current__Salesitems.forEach(item => {
                                        item.Salesitem.invprice = item.Salesitem.unitprice
                                        if(item.type == 'othercharge'){
                                            item.Salesitem.invqty = 1
                                        }
                                        item.Salesitem.invoiceamount = item.Salesitem.invprice * item.Salesitem.invqty
                                    })
                                    this.updateTotals('current')
                                    this.loader = false
                                })
                            })
                    } else {
                        alert('Invoice could not be initialised. Please contact APPIC developer for support.')
                    }
                }
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.invoice.update.Invoice
                },
                () => {
                    this.updatedInvoiceUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.invoice.current.Invoice
                },
                () => {
                    this.newInvoiceUnsaved = true
                },
                { deep: true }
            )
            this.$nextTick(function() {
                if(this.$route.matched.some(({name}) => name === 'update_invoice')){
                    this.checkLhdnValidation();
                }
            })
        }
    }
</script>

<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>